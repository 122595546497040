const ExpandItemElement = () => {
  const nodes = {};

  const getNodes = () => {
    nodes.accordionContainers = document.querySelectorAll('.js-expand-item');
  };

  const setAccordionElements = () => {
    nodes.accordions = [];
    nodes.accordionContainers.forEach(accordion => {
      accordion.classList.add('expand-item-enabled');
      const accordionOn = accordion.querySelector('.js-expand-item-on');
      if (accordionOn) {
        accordionOn.classList.add('expand-item-on');
      }
      const accordionOff = accordion.querySelector('.js-expand-item-off');
      if (accordionOff) {
        accordionOff.classList.add('expand-item-off');
      }
      const accordionContent = accordion.querySelector('.js-expand-item-content');
      if (accordionContent) {
        accordionContent.classList.add('expand-item-content');
      }
      accordion.addEventListener('click', event => {
        toggleDisplay(accordion);
      });
    });
  };

  const toggleDisplay = accordion => {
    if (accordion.classList.contains('active')) {
      accordion.classList.remove('active');
    } else {
      accordion.classList.add('active');
    }
  };

  const init = () => {
    getNodes();
    setAccordionElements();
  };

  return {
    init
  };
};

document.addEventListener('DOMContentLoaded', function() {
  ExpandItemElement().init();
});
